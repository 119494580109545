import React from 'react'
import { SellData } from './SellData'
import SellHome from './SellHome/SellHome'
import HowToBuy  from '../Buy/HowToBuy/HowToBuy'
import WhatWeDo from '../Home/WhatWeDo/WhatWeDo'
import SellValue from './SellValue/SellValue'
import HappyBuyers from '../Home/HappyBuyers/HappyBuyers'
import MeetFamily from '../Home/MeetFamily/MeetFamily'
import MoveYourFamily from '../SouthPark/MoveYourFamily'

const Sell = () => {
  return (
    <>
      <SellHome />

      <HowToBuy getMapData={SellData}   howToBuyHeading={
          <h2 className="font-IbarraRealNova text-d-2xl text-[#1A1A1A] font-[700] text-center sm:text-d-30">
            Get an <span className="font-Quicksand font-[500]">Accurate</span> Home Value
          </h2>  
        }
        lineHeight={64}
        />
      <SellValue />
      <div className="pt-[60px]">
      <WhatWeDo />
      </div>
      <HappyBuyers />
      <MeetFamily btn_title='Get My Estimate'/>
      <MoveYourFamily />
    </>
  )
}

export default Sell
